import { faArrowDown, faArrowUp, faFilter } from '@fortawesome/free-solid-svg-icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import ContentLoadFooter from './ContentLoadFooter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import ProductsGrid from './ProductsGrid'
import SearchBox from './SearchBox'
import { getProducts } from '../lib/api'
import useLoadAtBottom from '../lib/hooks/use-load-at-bottom'
import { useRouter } from 'next/router'

function ActiveLink({ link, title }) {
  return (
    <li className="mx-1">
      <Link href={link}>
        <a className="list-group-item border-0 py-1 px-1 rounded bg-secondary text-white">
          {title}
        </a>
      </Link>
    </li>
  )
}

export default function Catalog({ initialProducts, categories, query }) {
  const [page, setPage] = useState(1)
  const [reachedBottom, setReachedBottom] = useState(false)
  const [items, setItems] = useState([])
  const [fetching, setFetching] = useState(false)

  const itemsContainer = useRef()

  function getFilterParams(filter = {}) {
    const q = { ...query, ...filter }

    // prevents general-error [500] because category is empty and
    // cannot be cast to ObjectId
    if (!q.category) {
      delete q['category']
    }

    return Object.keys(q)
      .map((k) => `${k}=${q[k]}`)
      .join('&')
  }

  const fetchMore = useCallback(async () => {
    if (reachedBottom || fetching) return

    setFetching(true)

    const products = await getProducts({ ...query, $skip: page * 12 })
    setPage((page) => page + 1)

    setItems((items) => [...items, ...products.data])

    if (products.data.length === 0) {
      setReachedBottom(true)
    }

    setFetching(false)
  }, [page, query, reachedBottom, fetching])

  const router = useRouter()

  function changeCategory(category) {
    const url = getFilterParams({ category })
    router.push(`/products/?${url}`)
  }

  function changeSortDirection(direction) {
    const url = getFilterParams({ direction })
    router.push(`/products/?${url}`)
  }

  function changeSort(sort) {
    const url = getFilterParams({ sort })
    router.push(`/products/?${url}`)
  }

  function toggleFilters() {
    const filterMenu = document.getElementById('filterMenu')
    filterMenu.classList.toggle('collapsed')
  }

  useEffect(() => {
    setItems([...initialProducts])
    setPage(1)
    setReachedBottom(false)
  }, [initialProducts])

  useLoadAtBottom(itemsContainer.current, fetchMore)

  return (
    <>
      <div className="container mt-4">
        <div className="d-flex d-lg-none mb-3">
          <div className="flex-fill me-2">
            <SearchBox />
          </div>
          <div className="d-block w-25">
            <button className="btn btn-secondary text-white" onClick={toggleFilters}>
              <FontAwesomeIcon icon={faFilter} /> Filter
            </button>
          </div>
        </div>

        <div className="foldable d-lg-none d-block collapsed" id="filterMenu">
          <div className="d-flex">
            <div className="mb-2 w-100">
              <select
                name="category.filter"
                id="category-filter"
                className="form-select py-1"
                value={query.category}
                onChange={(v) => changeCategory(v.target.value)}
              >
                <option value="">All Products</option>
                {categories.map((category) => {
                  return (
                    <option value={category._id} key={category._id}>
                      {category.title}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <span>Sort by</span>
            <div className="d-inline-block mx-2">
              <select
                name="timeSort"
                className="form-select py-1"
                onChange={(v) => changeSort(v.target.value)}
                value={query.sort}
              >
                <option value="newest">Newest</option>
                <option value="price">Price</option>
              </select>
            </div>
            <button
              className="btn border rounded d-inline-block"
              onClick={() => {
                // default sort direction is descending (big -> small)
                const direction = query.direction === 'asc' ? 'desc' : 'asc'
                changeSortDirection(direction)
              }}
            >
              <FontAwesomeIcon
                icon={query.direction === 'desc' ? faArrowDown : faArrowUp}
                className="text-muted"
              />
            </button>
          </div>
        </div>

        <div className="row d-none d-lg-flex">
          <div className="col-lg-7 col-12">
            <div className="d-none d-md-block">
              <ul className="list-unstyled list-group-horizontal list-group">
                {!query.category ? (
                  <li className="mx-1">
                    <Link href="/products" passHref>
                      <a className="list-group-item border-0 py-1 px-2 rounded bg-secondary text-white cursor-pointer">
                        All Products
                      </a>
                    </Link>
                  </li>
                ) : (
                  <li className="mx-1">
                    <Link href={query.q ? `/products?q=${query.q}` : '/products'}>
                      <a
                        className={
                          'list-group-item border-0 py-1 px-2 cursor-pointer hover-light rounded'
                        }
                      >
                        All Products
                      </a>
                    </Link>
                  </li>
                )}
                {categories.slice(0, 3).map((category) => {
                  const link = `/products/?${getFilterParams({ category: category._id })}`

                  if (query.category === category._id) {
                    return <ActiveLink link={link} title={category.title} key={category._id} />
                  }

                  return (
                    <li key={category._id} className="mx-1">
                      <Link href={link}>
                        <a
                          className={
                            'list-group-item border-0 py-1 px-1 cursor-pointer hover-light rounded'
                          }
                        >
                          {category.title}
                        </a>
                      </Link>
                    </li>
                  )
                })}
                {categories.length > 3 && (
                  <div>
                    <select
                      name="category.filter"
                      id="category-filter"
                      className="form-select py-1"
                      value={query.category}
                      onChange={(v) => changeCategory(v.target.value)}
                    >
                      {categories.slice(3).map((category) => {
                        return (
                          <option value={category._id} key={category._id}>
                            {category.title}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                )}
              </ul>
            </div>
          </div>

          <div className="col-12 col-lg-3">
            <div className="d-flex justify-content-end align-items-center">
              <span>Sort by</span>
              <div className="d-inline-block mx-2">
                <select
                  name="timeSort"
                  className="form-select py-1"
                  onChange={(v) => changeSort(v.target.value)}
                  value={query.sort}
                >
                  <option value="newest">Newest</option>
                  <option value="price">Price</option>
                </select>
              </div>
              <button
                className="btn border rounded d-inline-block"
                onClick={() => {
                  // default sort direction is descending (big -> small)
                  const direction = query.direction === 'asc' ? 'desc' : 'asc'
                  changeSortDirection(direction)
                }}
              >
                <FontAwesomeIcon
                  icon={query.direction === 'desc' ? faArrowDown : faArrowUp}
                  className="text-muted"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid py-lg-5 py-2" ref={itemsContainer}>
        <ProductsGrid products={items} />

        {items.length < 1 && (
          <>
            <p className="text-center">
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src="https://compa.eu-central-1.linodeobjects.com/adeton/empty-state.jpg"
                alt="empty"
                className="img-fluid"
                width={300}
                loading="lazy"
              />
            </p>

            <p className="text-muted text-center">No products available. Come back later.</p>
          </>
        )}

        <ContentLoadFooter fetching={fetching} reachedBottom={reachedBottom} />
      </div>
    </>
  )
}
