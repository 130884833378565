import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons'
import { useRouter } from 'next/router'

// TODO: [Later] Finish preview widget
export default function Cart() {
  const router = useRouter()

  function moveToCheckout() {
    router.push('/checkout')
  }

  return (
    <div className="ad__cart-widget">
      <div
        className="bg-secondary p-3 rounded-circle d-flex align-items-center justify-content-center shadow cursor-pointer"
        style={{ height: 60, width: 60 }}
        onClick={moveToCheckout}
      >
        <FontAwesomeIcon icon={faShoppingBasket} color={'white'} style={{ fontSize: 24 }} />
      </div>
    </div>
  )
}
