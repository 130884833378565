import { useEffect } from 'react'

export default function useLoadAtBottom(el, fn) {
  useEffect(() => {
    if (!el) {
      return
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio >= 0.4) {
          fn()
        }
      })
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    })

    observer.observe(el.querySelector('.content-load-footer'))

    return () => observer.disconnect()
  }, [fn, el])
}
