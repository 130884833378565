export default function ContentLoadFooter({ fetching, reachedBottom }) {
  let inner = null

  if (fetching) {
    inner = <progress className="circular-progress small text-primary" />
  }

  if (reachedBottom) {
    inner = <div className="rounded-circle bg-primary d-inline" style={{ height: 8, width: 8 }} />
  }

  return (
    <div className="d-flex justify-content-center py-3 content-load-footer" style={{ height: 47 }}>
      {inner}
    </div>
  )
}
