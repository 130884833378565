import Link from 'next/link'
import { shortenText } from '../lib/utils'

export default function ProductsGrid({ products }) {
  return (
    <div className="row gx-2 gx-lg-3">
      {products.map((it) => {
        return (
          <div className="col-lg-2 col-6 col-md-4 cursor-pointer" key={it._id}>
            <Link href={`/products/${it._id}`} className="cursor-pointer" passHref>
              <div>
                <div
                  className="ratio ratio-3x4 bg-cover rounded"
                  style={{ backgroundImage: `url(${it.images[0].url})` }}
                ></div>

                <div className="row mt-2">
                  <div className="col-lg-8">
                    <p className="h6 mb-0">{shortenText(it.title, 30)}</p>
                    <p className="text-muted mb-0">{it.category.title}</p>
                  </div>

                  <div className="col-lg-4">
                    {it.sellAt ? (
                      <>
                        <p className="text-lg-end">
                          {it.price.currency} {it.sellAt} <br />
                          <span
                            className="text-muted strikethrough"
                            style={{ textDecoration: 'line-through' }}
                          >
                            {it.price.currency} {it.price.value}
                          </span>
                        </p>
                      </>
                    ) : (
                      <p className="text-lg-end">
                        {it.price.currency} {it.price.value.toFixed(2)}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        )
      })}
    </div>
  )
}
